const mainPath = "/images/memorial-2021/";
export const photos: any[] = [
    {
        src: `${mainPath}andrea_john.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}atkins.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}078.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Barnards.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Bates.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}boccardo.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Daniels.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}GregWilkes.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Hanhart.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}harding.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Hintons.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Hipkins.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}James.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}jeffery.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Johnstons.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}long.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}mamalis.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}maya_rai.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Ododoru.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Saliba.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Salmons.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}Taylors.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}taylors2.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}thapas.jpeg`,
        width: 1,
        height: 1,
    },
].map(
    (_) => ({ ..._ , source: _.src }),
);

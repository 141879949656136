import React from "react";
import { GroupMembers } from "../../components/groupMembers";
import { IGroup } from "../../data/groupAllocations";
import { AllocationsContext } from "../../store";
import { makeStyles } from '@material-ui/styles';
import { Card, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: (theme as any).spacing(4),
    },
  }));

const Groups: React.SFC = () => {
     const groups: IGroup[] = React.useContext<IGroup[]>(AllocationsContext);
     const classes = useStyles();
     return (
      <div className={classes.root}>
        <Grid container spacing={4}>
            <Card className="card scripture">
                <h3>Our Field Service Groups:</h3>
              {
                groups.map((group: IGroup) => (
                  <GroupMembers name={group.name} members={group.members} />
                ))
              }
            </Card>
          </Grid>
        </div>
     )
};

export default Groups;

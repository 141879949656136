import { ThemeProvider } from "@material-ui/styles";
import { createBrowserHistory } from "history";
import React from "react";
import { Router } from "react-router-dom";

// import Button from "@material-ui/core/Button";
// tslint:disable-next-line:no-submodule-imports
import "react-perfect-scrollbar/dist/css/styles.css";
import "./App.css";
import "./assets/scss/index.scss";
import Routes from "./Routes";
import theme from "./theme";
const browserHistory = createBrowserHistory();

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;

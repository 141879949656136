import * as React from 'react';
import Card from "@material-ui/core/Card";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

export default () => {
    const classes = useStyles();

    return (
        <Card>
            <Typography className={classes.root}>
                    <Typography variant="h6" paragraph={true} align="center">
                        Noticeboard:
                    </Typography>
                    <Typography variant="body1" paragraph={true} align="center">
                        <Link href="https://london-ruislip.co.uk/KHT.pdf">Kingdom Hall Trust - proposal to merge</Link>
                    </Typography>
            </Typography>
        </Card>
        );
};

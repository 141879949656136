import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import VideocamIcon from '@material-ui/icons/Videocam';
import { makeStyles } from "@material-ui/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EventIcon from '@material-ui/icons/Event';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import NotificationsIcon from '@material-ui/icons/Notifications';
import clsx from "clsx";
import React from "react";
import AuthService from '../../../../services/AuthService';

import { SidebarNav } from "./components";

const auth = new AuthService();

const useStyles = makeStyles((theme: any) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props: any) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();


  const pages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Video Conferences",
      href: "/video-conferences",
      icon: <VideocamIcon />,
    },
  ];

  if (auth.isAuth()) {
    pages.push({
      title: "Groups",
      href: "/groups",
      icon: <PeopleIcon />,
    });
    pages.push({
      title: 'Meeting Schedules',
      href: '/schedules',
      icon: <EventIcon />,
    });

    pages.push({
      title: 'Memorial 2021',
      href: '/memorial-2021',
      icon: <CameraEnhanceIcon />,
    });

    pages.push({
      title: 'Memorial 2020',
      href: '/memorial-2020',
      icon: <CameraEnhanceIcon />,
    });
    pages.push({
      title: 'Noticeboard',
      href: '/noticeboard',
      icon: <NotificationsIcon />,
    });

    pages.push({
      title: 'Logout',
      href: '/logout',
      icon: <ExitToAppIcon />,
    });


  } else {
    pages.push({
      title: 'Login',
      href: '/login',
      icon: <VpnKeyIcon />,
    });
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;

// tslint:disable:no-submodule-imports
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { Assignment, IGroup } from "../data/groupAllocations";

interface IColumn {
  id: "name";
  label: string;
  minWidth?: number;
}

const columns: IColumn[] = [
  { id: "name", label: "Group Members:", minWidth: 170 },
];

const useStyles = makeStyles({
  root: {
    width: "90%",
  },
  container: {
    maxWidth: "80%",
    margin: "0 auto 10px auto",
  },
  heading: {
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "center",
  },
});

export const GroupMembers: React.SFC<IGroup> = ({ name, members }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={name}
        id={name}
      >
        <Typography className={classes.heading}>{name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <TableContainer className={classes.container}>
          <Table stickyHeader={true} aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => {
                return (
                  <TableRow hover={true} role="checkbox" tabIndex={-1} key={member.name}>
                    {columns.map((column) => {
                      const value = member[column.id];
                      return (
                        <TableCell key={column.id} align={"left"} style={{
                          color: member.assignment === Assignment.ELDER ?
                            "red" : (member.assignment === Assignment.MINISTERIAL_SERVANT ? "blue" : "black"),
                        }}>
                          {value} {member.isGroupOverseer ? "(Group Overseer)" : ""}
                          {member.isAssistant ? "(Assistant)" : ""}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

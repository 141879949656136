export default class AuthService {
    private isAuthenticated: boolean;
    constructor() {
        this.isAuthenticated = false;
    }

    public logout() {
        localStorage.removeItem("Authorized");
        localStorage.removeItem("Auth-token");
        this.isAuthenticated = false;
    }

    public isAuth() {
        this.isAuthenticated = !!localStorage.getItem("Authorized") && !!localStorage.getItem("Auth-token");
        return this.isAuthenticated;
    }

    public async authenticate(username: string, password: string) {
      const result = await fetch("https://api.london-ruislip.co.uk/auth", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username,
                password,
            }),
        });
      const r = await result.json();
      if (r.body && r.body === "Authorised" && r.requestId) {
        localStorage.setItem("Authorized", "true");
        localStorage.setItem("Auth-token", r.requestId);
        this.isAuthenticated = true;
      } else {
          this.logout();
      }
      return this.isAuthenticated;
    }
}

import * as React from 'react';
import Card from "@material-ui/core/Card";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

export default () => {
    const classes = useStyles();

    return (
        <Card>
            <Typography className={classes.root}>
                    <Typography variant="h6" paragraph={true} align="center">
                        Meeting Schedules:
                    </Typography>
                    <Typography variant="body1" paragraph={true} align="center">
                        <Link href="https://london-ruislip.co.uk/public_talk.pdf">Public Talks</Link>
                    </Typography>
                    <Typography variant="body1" paragraph={true} align="center">
                        <Link href="https://london-ruislip.co.uk/clmm_02_2024.pdf">
                          Midweek meeting - February 2024
                        </Link>
                    </Typography>
                    <Typography variant="body1" paragraph={true} align="center">
                        <Link href="https://london-ruislip.co.uk/clmm_03_2024.pdf">
                          Midweek meeting - March 2024
                        </Link>
                    </Typography>
                    <Typography variant="body1" paragraph={true} align="center">
                        <Link href="https://london-ruislip.co.uk/clmm_04_2024.pdf">
                          Midweek meeting - April 2024
                        </Link>
                    </Typography>
            </Typography>
        </Card>
        );
};

import React from "react";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from '@material-ui/core/Button';
import {
    useHistory,
    useLocation,
  } from "react-router-dom";

import AuthenticationService from '../../services/AuthService';
const auth = new AuthenticationService();
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: "25ch",
        },
    }),
);

interface ILoginState {
    username: string;
    password: string;
    showPassword: boolean;
    submitted: boolean;
}


export default function LoginView() {
    const classes = useStyles();
    const [values, setValues] = React.useState<ILoginState>({
        username: "",
        password: "",
        showPassword: false,
        submitted: false,
    });

    const history = useHistory();
    const location = useLocation();

    const { from } = location.state as any || { from: { pathname: "/" } } as any;

    const handleChange = (prop: keyof ILoginState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const login = () => {
        setValues({
            ...values,
            submitted: true,
        });
        auth.authenticate(values.username, values.password).then(result => {
            if (result === true) {
                console.log('location',location);
                history.replace(from);
            } else {
                alert('Wrong credentials!');
                setValues({
                    ...values,
                    submitted: false,
                });
            }
        }).catch(error => console.log(error));
    }

    return (
        <div className={classes.root}>
            <Card className="card">
            <TextField
                    label="username"
                    id="username"
                    value={values.username}
                    onChange={handleChange("username")}
                    className={clsx(classes.margin, classes.textField)}
            />
            <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
             </FormControl>
             <Button variant="contained" color="primary" onClick={login} disabled={values.submitted}>
                Submit
            </Button>
             </Card>
        </div>
    );
}

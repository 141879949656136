import * as React from "react";
import { makeStyles } from '@material-ui/styles';
import { Card, Grid, Typography, Divider } from '@material-ui/core';
import zoomImage1 from './zoom_images/image001.png';
import zoomImage2 from './zoom_images/image002.png';
import zoomImage3 from './zoom_images/image003.png';
import zoomImage4 from './zoom_images/image004.png';
import zoomImage5 from './zoom_images/image005.png';

const useStyles = makeStyles((theme) => ({
    root: {
    //   padding: (theme as any).spacing(4),
    },
  }));


const VideoConference = () => {

    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Grid container spacing={0}>
                    <Card className="" style={{
                        padding: '5%',
                    }}>
                            <Typography variant="h4" paragraph={true} align="center">
                                Joining the meeting by computer, phone or tablet
                            </Typography>
                            <Typography variant="h5" paragraph={true} align="left">
                                Using a PC or Mac
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                If you are using a PC or Mac,&nbsp;
                                you do not have to download any software to join the meeting.
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                1. Use the meeting link in your web browser&nbsp;
                                <a href="https://zoom.us/j/565694529">https://zoom.us/j/565694529</a>
                            </Typography>
                            <Typography variant="body1" align="left" paragraph={true} style={{
                                color: 'red',
                            }}
                            >
                                Notice that you will be asked to enter a <strong>meeting password</strong> in order to join.
                                This will be made available to you by the body of elders.
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                2. Use the 'click here' link to join the meeting
                            </Typography>
                            <img className="img-fitted" src={zoomImage1} alt=""/>
                            <Typography variant="body1" paragraph={true} align="left">
                                    3. You will see an additional message. Now click 'join from your browser':
                            </Typography>
                            <br/><img className="img-fitted" src={zoomImage2} alt=""/>
                            <Typography variant="body1" paragraph={true} align="left">
                                4.Next, you must enter your name, and then click <b>Join</b> to
                                enter the meeting&nbsp;
                                (Please give your full name, or family names if you are meeting as a family):
                                <img className="img-fitted" src={zoomImage3} alt=""/>
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                5. If the meeting has been started, you will now enter.
                                Use the buttons in the bottom left of the screen to use your microphone and video.
                                Your browser will ask you permission.
                                You must click 'Allow':
                            </Typography>
                                <img className="img-fitted" src={zoomImage4} alt=""/><br/>
                                <Typography paragraph={true} >
                                    <img className="img-fitted" src={zoomImage5} alt=""/>
                                </Typography>
                            <Typography variant="body1" paragraph={true} align={"left"}>
                                If you would like to download the Zoom app&nbsp;
                                instead of using your browser, you can do so here:&nbsp;
                                <a href="https://zoom.us/support/download">https://zoom.us/support/download</a>
                            </Typography>
                            <Divider />
                            <Typography variant="h5" paragraph={true} align="center">
                                From your Phone or Tablet
                            </Typography>

                            <Typography variant="body1" paragraph={true} align="left">
                            If you are using your phone or tablet, you must download the Zoom Cloud Meetings app&nbsp;
                            from the App Store (iOS) or Play Store (Android).
                            </Typography>

                            <Typography variant="body1" paragraph={true} align="left">
                                1. Click on the link: <a href="https://zoom.us/j/565694529">
                                    https://zoom.us/j/565694529</a>
                                or copy/paste into a web browser
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                2.	The Zoom app will open and will ask for your name. and you can enter the meeting
                                <p>&nbsp;&nbsp;&nbsp;
                                    a. Please give your full name, or family names if you are meeting as a family.</p>
                            </Typography>
                            <Divider />
                            <br />
                            <Typography variant="h4" paragraph={true} align="center">
                                Joining the meeting by phone
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                We are going to try to broadcast the meeting via the KHCONF service at the Kingdom Hall,
                                so you can listen as you normally would using this system.
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                Additionally you can join using your home phone,&nbsp;
                                using the number: <strong>0203 481 5240</strong><br/>
                                You will be prompted for the meeting ID which is: <strong>565 694 529</strong><br/>
                            </Typography>
                            <Divider />
                            <br />
                            <Typography variant="h4" paragraph={true} align="center">
                                When you are in the meeting
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                 When the meeting is in progress you should bear in mind the following:
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                 <li>If your microphone is on, everyone will hear you</li>
                                    <li>If you are using your camera, make sure you have enough lighting so you can be seen</li>
                                    <li>Please use only one device per room, otherwise this will cause audio problems (echoing/feedback)</li>
                                    <li>If you are using a phone, tablet or laptop, make sure it is sufficiently charged or keep it plugged in</li>
                            </Typography>
                            <Typography variant="h5" paragraph={true} align="left">
                                How to comment
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                 <li>On your phone or iPad, press the 'More' button and select 'Raise Hand'</li>
                                    <li>On you PC/Mac, press the Participants button, and select 'Raise Hand'</li>
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                            The brother conducting the item, or hosting the meeting will say who has been selected and most of the time will automatically unmute your microphone.
                            </Typography>
                            <Divider />
                            <br />
                            <Typography variant="h4" paragraph={true} align="center">
                                Troubleshooting
                            </Typography>
                            <Typography variant="body1" paragraph={true} align="left">
                                If you have any issues connecting, please contact one of the elders, or another brother/sister who is familiar with the system.
                            </Typography>
                    </Card>
            </Grid>
        </div>);
};

export default VideoConference;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Grid, Typography, Link, Button, Dialog, AppBar,
Toolbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { NavLink as RouterLink } from "react-router-dom";
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: (theme as any).spacing(4),
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: (theme as any).spacing(2),
      flex: 1,
    },
  }));

  const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Dashboard = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
              <Grid
                item
              >
                <Card className="card scripture">
                  <Typography variant="body2" align="center">
                    "Do not be anxious over anything, but in everything by prayer
                    and supplication along with thanksgiving,
                    let your petitions be made known to God;&nbsp;
                    and the peace of God that surpasses all understanding will guard
                    your hearts and your mental powers by means of Christ Jesus."</Typography>
                  <Typography variant="subtitle2">(Philippians 4:6,7)</Typography>
                </Card>
              </Grid>
              <Grid
                item
              >
                <Card className="card scripture">
                  <Typography variant="h6" align="center" paragraph={true}>
                      Mid-week and Weekend Meetings via Video-Conferencing
                  </Typography>
                  <Typography variant="body1" align="center" paragraph={true}>
                  To join the Zoom meeting, use the link below:
                  <br/>
                    <a href="https://zoom.us/j/565694529">https://zoom.us/j/565694529</a>
                  <br />
                  </Typography>
                  <Typography variant="h6" align="center" paragraph={true}>
                      Field service meetings via Video-Conferencing
                  </Typography>
                  <Typography variant="body1" align="center" paragraph={true}>
                  To join the Zoom meeting, use the link below:
                  <br/>
                    <a href="https://zoom.us/j/82155151881">https://zoom.us/j/82155151881</a>
                  <br />
                  </Typography>
                  <Typography variant="body1" align="left" paragraph={true} style={{
                    color: 'red',
                  }}
                  >
                    Notice that you will be asked to enter a <strong>meeting password</strong> in order to join.
                    This will be made available to you by the body of elders.
                  </Typography>
                  <Typography variant="h5" align="left" paragraph={true}>
                    Further instructions can be found here: <strong><Link><RouterLink to="/video-conferences">Instructions</RouterLink></Link></strong>.
                  </Typography>
                  <Typography variant="h5" align="center" paragraph={true}>
                     Please read our meeting reminders!!!<br/>
                     <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Open Meeting reminders
                    </Button>
                    <Dialog fullScreen={true} open={open} onClose={handleClose} TransitionComponent={Transition} scroll={"body"}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Meeting Reminders
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Card style={{ padding: "5px 10px 10px 5px"}}>
            <Typography variant="h4" align={"center"} paragraph={true}>
                  Meeting Reminders
            </Typography>
            <Typography variant="body1" align="left" paragraph={true}>
            <br/>
            The purpose and benefit of having a VC is for our meetings to mirror the real thing, as far as possible, and enjoy spiritual association. However, a VC is very different to our normal meeting experience, so it important that that we remember the following principles to help us get the best from them.<br/><br/>
<strong>Our meetings are provided for us to worship Jehovah (Psalms 1:22)</strong><br />
It could be very easy to forget that we are at a meeting when we are in the familiar surroundings of our own homes and then not give our meetings the respect they deserve. To help us to avoid doing this, it may be helpful for us to
<li>View our home as the KH for the duration of the meeting</li>
<li>Dress appropriately (perhaps as if we were going to the KH)</li>
<li>Make sure that we fully focused and not distracted by unnecessary matters</li>
<br/>
<br/>
<strong>We meet together so that we can encourage one another (Heb 10:25)</strong>
<br/>
<li>Prepare well and prepare to comment to encourage one another at the meeting</li>
<li>Show love for each other by listening attentively and respectfully</li>

<strong>Meeting Format</strong>
<li>The VC meetings will follow the normal meeting times we have at present.</li>
<li>New CLMM and Public Talk schedules will be posted on our new Electronic Notice Board.</li>
<li>In the event that VC meetings cannot be held due to the unavailability of brothers, a link to view an online meeting will be provided, if this is available, as a last resort.</li><br/><br/>

<strong>Mid week Meetings</strong><br/>
<strong><i>Treasures From God's Word: </i></strong>This part of the meeting will run as normal with brothers assigned to deliver their items through the VC system.<br/>
<strong><i>Apply Yourself to the Field Ministry: </i></strong> This part of the meeting will be reduced but will have at least one student talk assigned to allow brothers and sisters to take part. For practical reasons, we are limited to members of the same household being assigned to give student talks.<br/>
<strong><i>Living As Christians:  </i></strong> This part of the meeting will run as normal with brothers assigned to deliver their items through the VC system. The congregation will have opportunity to comment in items that call for audience participation.<br /><br/>

<strong>Public Meeting</strong>
<strong><i>Public Talk: </i></strong>A local brother will be assigned to do a Public Talk each week.<br/>
<strong><i>WatchTower Study: </i></strong>The WT will be held weekly by the WT Overseer and the congregation will have opportunity to participate as normal through the VC system.<br/>

<br/><i>TIP: Any member of the congregation can contact the WT Overseer (Gareth Salmon) with the paragraph they would like to answer on to make sure they are not missed on the system.<br /></i>

            </Typography>
        </Card>
      </Dialog>




                  </Typography>
                </Card>
              </Grid>
            </Grid>
    </div>
    );
};

export default Dashboard;

export enum Assignment {
    ELDER = "ELDER",
    MINISTERIAL_SERVANT = "MINISTERIAL_SERVANT",
    PUBLISHER = "PUBLISHER",
}

export interface IGroupMember {
    name: string;
    isAssistant?: boolean;
    isGroupOverseer?: boolean;
    assignment: Assignment;
}

export interface IGroup {
    name: string;
    members: IGroupMember[];
}

const OakingtonAvenue: IGroup = {
    name: 'Oakington',
    members: [
{
            name: "SALMON John",
            isGroupOverseer: true,
            assignment: Assignment.ELDER,
        },
{
            name: "SALMON Andrea",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "JOHNSTON Thomas",
            isAssistant: true,
            assignment: Assignment.ELDER,
        },
{
            name: "JOHNSTON Carole",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "CHACKSFIELD Carmen",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "CHACKSFIELD Alistair",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "ATKINS Sherrill",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "TEMPLE Corrine",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "THAPA Reukha",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "THAPA Subrat",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "BATES Terry",
            isAssistant: false,
            assignment: Assignment.ELDER,
        },
{
            name: "BATES Jenny",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "BATES Jackie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "THAPA Wrishi",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "THAPA Shrijana",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "HINTON Ben",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "HINTON Katie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "HINTON Thomas",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "HINTON Samuel",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "DANIEL Pippa",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "DANIEL Amber",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "DANIEL Layla",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "STANISLAUS Resario",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "STANISLAUS Jega",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "KEMP Gary",
            isAssistant: false,
            assignment: Assignment.ELDER,
        },
        {
            name: "KEMP Stella",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "RAI Maya",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
    ]
}

const Flemming = {
    name: "Flemming ",
    members: [
        {
            name: "DEWDNEY, Philip",
            isAssistant: false,
            isGroupOverseer: true,
            assignment: Assignment.ELDER,
        },
        {
            name: "DEWDNEY, Greta",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "HARDING, Chris",
            isAssistant: true,
            assignment: Assignment.ELDER,
        },
        {
            name: "HARDING, Jackie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "HARDING, Fiona",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "FORD, Shirley",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "DONALDSON, Audrey",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "BARR, Hazel",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "GLEDHILL, Georgie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "BARNARD, Tony",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "BARNARD, Debbie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "BARNARD, Emily",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "HODGES, Asa",
            isAssistant: false,
            assignment: Assignment.ELDER,
        },
        {
            name: "ARNOLD, Derek",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "ARNOLD, Angela",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "ARNOLD, Dario",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "ARNOLD, Marina",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "VOINKWEL, Jenny",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "ODODURU, Akpe",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "ODODURU, Ruth",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "LORENTSEN, Tom",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "LORENTSEN, Laurel",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },

    ],
};

const Cannonbury = {
    name: 'Cannonbury ',
    members: [
        {
            name: "DALY Russell",
            isGroupOverseer: true,
            assignment: Assignment.ELDER,
        },
        {
            name: "MAMALIS Kostas",
            isAssistant: true,
            assignment: Assignment.ELDER,
        },
{
            name: "MAMALIS Abigail",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "MAMALIS Debbie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "MAMALIS Ethan",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "TINSLEY Chris",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "TAYLOR Lynn",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "TAYLOR Corrine",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "TAYLOR Emma",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "TAYLOR Lucy",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "BOCCARDO Edna",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "BOCCARDO Luca",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "BOCCARDO Mica",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "YEAP YuenTing",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "CHILVERS Alexandra",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "POWIS Sean",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "POWIS Brenda",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "POWIS Chloe",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "JEFFREY Jonny",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "JEFFREY Maria",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "TINSLEY Sheila",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "HINTON Aaron",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "HINTON Matthew",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "DOWNEY Angela",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
    ],
};

const Marlborough = {
    name: 'Marlborough',
    members: [
{
            name: "SALMON Gareth",
            isGroupOverseer: true,
            assignment: Assignment.ELDER,
        },
{
            name: "SALMON Lois",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "SALMON Aimee",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "SALMON Daisy",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "HIPKIN Darren",
            isAssistant: true,
            assignment: Assignment.MINISTERIAL_SERVANT,
        },
{
            name: "HIPKIN Lisa",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "MCKITTERICK Lena",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "WILKES Greg",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "WILKES Sara",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "WILKES Chloe",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "WILKES Charlie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "BARNARD Sonia",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "LONG Daniele",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "WILSON, Paul",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },{
            name: "WILSON, Tony",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },{
            name: "WILSON, Pat",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "SALIBA Debbie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "ISLE Jackie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
    ],
};

const Keswick = {
    name: 'Keswick ',
    members: [
{
            name: "WILKES, Len",
            isGroupOverseer: true,
            assignment: Assignment.ELDER,
        },
{
            name: "WILKES, Kim",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "WILKES, Angie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
        {
            name: "JAMES Peter",
            isAssistant: true,
            assignment: Assignment.ELDER,
        },
{
            name: "JAMES Kathy",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "WILLIAMS, George",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "DOWDEN, Claire",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "DOWDEN, Olivia",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "FITZMAURICE, Kim",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "HANHART, Katie",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "JAMES, Danny",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "JAMES, Karla",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "JAMES, Rebecca",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "JAMES, Sarah",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
{
            name: "GOLUBEVA, Nadezda (Nadia)",
            isAssistant: false,
            assignment: Assignment.PUBLISHER,
        },
    ]
};

export const GroupAllocations: IGroup[] = [
    Flemming,
    OakingtonAvenue,
    Cannonbury,
    Marlborough,
    Keswick,
];

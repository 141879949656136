import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
}));

const Topbar = (props: any) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <p style={
            {
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "Arial",
              color: "white"
            }
          }>London Ruislip</p>
          <Typography variant="caption">announcements</Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;

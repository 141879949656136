import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import RouteWithLayout from "./components/RouteWithLayout";
import { Main as MainLayout } from "./layouts";
import AuthenticationService from "./services/AuthService";

import {
    Dashboard as DashboardView,
    Groups as GroupsView,
    Login as LoginView,
    Memorial2020 as Memorial2020View,
    Memorial2021 as Memorial2021View,
    Noticeboard as NoticeBoardView,
    Schedules as SchedulesView,
    VideoConference as VideoConferencesView,
  } from "./views";

const auth = new AuthenticationService();

function PrivateRoute(props) {
    if (auth.isAuth()) {
        return (
            <RouteWithLayout
                {...props}
            />
        );
    }
    return (
        <Redirect
            from={
                props.path
            }
            to={{
                pathname: "/login",
                state: {
                    from: props.path,
                },
            }}
        />
        );
}

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/dashboard"
            />
            <RouteWithLayout
                path="/login"
                exact={true}
                layout={MainLayout}
                component={LoginView}
            />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path="/dashboard"
            />
            <RouteWithLayout
                component={VideoConferencesView}
                exact
                layout={MainLayout}
                path="/video-conferences"
            />
            <PrivateRoute
                component={GroupsView}
                exact
                layout={MainLayout}
                path="/groups"
            />
            <PrivateRoute
                component={SchedulesView}
                exact
                layout={MainLayout}
                path="/schedules"
            />
            <PrivateRoute
                component={Memorial2020View}
                exact
                layout={MainLayout}
                path="/memorial-2020"
            />
            <PrivateRoute
                component={Memorial2021View}
                exact
                layout={MainLayout}
                path="/memorial-2021"
            />
            <PrivateRoute
                component={NoticeBoardView}
                exact
                layout={MainLayout}
                path="/noticeboard"
            />
            <Route
                path="/logout"
                component={() => {
                auth.logout();
                return <Redirect
                    exact
                    from="/"
                    to="/dashboard"
                />;
              }
              }
            />
        </Switch>
    );
};

export default Routes;

const mainPath = "/images/memorial-2020/";
export const photos: any[] = [
    {
        src: `${mainPath}andrea-john.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}arnolds.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}barnards.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}bates.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}boccardos.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}dan_james.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}daniels.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}dowdens.jpeg`,
        width: 1,
        height: 1,
    },
    {
        src: `${mainPath}fitzmaurice.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}johnstons.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}hintons.JPG`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}hipkins.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}kemps.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}lois_gareth.jpeg`,
        width: 1,
        height: 1,
    },{
        src: `${mainPath}len_kim.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}mamalis.JPG`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}powis.JPG`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}stanislaus.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}taylors.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}thapas.jpeg`,
        width: 1,
        height: 1,
    }, {
        src: `${mainPath}wilson.jpeg`,
        width: 1,
        height: 1,
    },
].map(
    (_) => ({ ..._ , source: _.src }),
);
